.logo {
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-PRIMARY_COLOR, #37b4af);
}

.logoCollapsed {
  width: 0px;
}

.image {
  height: 32px;
  display: block;
  width: auto;
}
