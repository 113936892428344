.routename {
  position: relative;
  font-family: lato, sans-serif;
  color: #808080;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
  display: flex;
  text-align: start;
}

.active {
  font-weight: bold;
  font-family: lato-bold, sans-serif;
  color: var(--color-PRIMARY, '#37B4AF');
  padding-left: 16px;
  background-color: var(--color-PRIMARY_TINT_4, '#E8F6F6');
}

.activeRoute {
  color: var(--color-PRIMARY, '#37B4AF');
  font-weight: bold;
}

.padding {
  padding: 12px;
}

.paddingLeft {
  padding-left: 24px;
}

.activeIndicator {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-left: 4px solid var(--color-PRIMARY, '#37B4AF');
}