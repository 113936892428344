.cardContainer {
  min-width: 400px;
}

.glogin {
  width: 100%;
}

.tab {
  padding: 24px;
  width: 50%;
  background: #f5f5f5;
}

.currentTab {
  background: #ffffff;
}

.emailContainer {
  padding: 0 32px;
}


.forgotPwdContainer {
  min-width: 500px;
  margin-top: 24px;
}

.forgotPwdContainerMobile {
  min-width: 300px;
  margin-top: 24px;
}

.fpinnerContainer {
  padding: 16px 32px;
}