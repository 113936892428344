.sidebar {
  background-color: white;
  width: 200px;
  position: relative;
  transition: all 0.5s ease;
}

.sidebarLinkContainer {
  overflow-y: auto;
  height: 90%;
}

.sidebarLinkContainerSmall {
  height: 72%;
}

.sidebarCollapsed {
  width: 72px;
}

.hideSidebar {
  width: 12px;
}
