.user {
  display: flex;
}

.logout {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
}

.font {
  font-family: lato, sans-serif;
  margin: 0;
  margin-left: 8px;
  font-size: 16px;
}

.fontsmall {
  font-size: 12px;
  color: var(--color-GRAY_60, #979797);
}

.userinfo {
  margin: auto;
  width: 176px;
  margin-left: 25px;
}

.collapsable {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: auto;
}