body {
  overflow-y: hidden;
}

.navbar {
  display: flex;
  width: 100%;
  height: 100vh;
}

.children {
  height: inherit;
  width: 100%;
  box-sizing: border-box;
  background-color: #f6f6f6;
  overflow-x: auto;
}

.childHasTopbar {
  padding-top: 60px;
}
